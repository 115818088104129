<template>
  <b-card
    :header="$i18n.t('navigation.categories')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <b-button
      v-if="isAddorDeleteAllowed"
      class="city-button mb-2"
      variant="primary"
      size="sm"
      to="/add-category"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>{{ $t("addons.create") }}</span>
    </b-button>
    <CategoryInfoPopup
      :category="catForPopup"
      :fetch="handleGetCategories"
      :delete-category="deleteCategory"
      :roles="roles"
      :handlePopupUpdate="handlePopupUpdate"
    />
    <!-- filters and search -->
    <FiltersAndSearch
      :id.sync="id"
      :searchKey.sync="searchKey"
      :sortOrder.sync="sortOrder"
      disableActiveOnly
    />
    <!-- end of filters and search -->
    <b-button variant="primary" class="mb-1" @click="exportHandler()">
      {{ $t("global.export") }}
    </b-button>
    <div class="scrollme">
      <b-table
        sticky-header
        hover
        :items="categoriesList"
        :fields="fields"
        responsive
        bordered
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        small
        :busy="isFetching"
        class="shadow-sm rounded"
        @row-clicked="onClickCategoryRow"
      >
        <template #cell(actions)="data">
          <b-button
            v-if="false && isAddorDeleteAllowed"
            variant="flat-danger"
            class="btn-icon"
            @click="deleteCategory('category', data.item)"
          >
            <feather-icon size="16" icon="TrashIcon" />
          </b-button>
          <b-button
            variant="flat-success"
            class="btn-icon"
            @click="onClickCategoryRow(data.item)"
          >
            <feather-icon
              v-if="isAddorDeleteAllowed"
              size="16"
              icon="EditIcon"
            />
            <feather-icon v-else size="16" icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>
      <Pagination
        :fetch-data="handleGetCategories"
        :skip.sync="skip"
        :total-rows="totalRows"
        :take.sync="take"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BTable, BButton } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import isAllowed from "@core/utils/isAllowed";
import Pagination from "@/views/components/Pagination/Pagination.vue";
import FiltersAndSearch from "../components/FiltersAndSearch/FiltersAndSearch.vue";
import CategoryInfoPopup from "./CategoryInfoPopup.vue";

const FileSaver = require("file-saver");

export default {
  components: {
    BCard,
    BTable,
    CategoryInfoPopup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Pagination,
    FiltersAndSearch,
  },
  // mixins: [isAllowed],
  data() {
    return {
      catForPopup: null,
      categoriesList: [],
      isFetching: false,
      sortOrder: 2,
      id: "",
      searchKey: "",
      totalRows: 0,
      skip: 0,
      take: 20,
    };
  },
  computed: {
    ...mapState("parametersModule", ["categories", "category"]),
    ...mapState("accountSettings", ["roles"]),
    isAddorDeleteAllowed() {
      return isAllowed(["Admin", "Editor"], this.roles);
    },
    fields() {
      return [
        {
          key: "actions",
          label: this.$t("global.action"),
        },
        {
          key: "productCategoryId",
          label: "ID",
        },
        {
          key: "productCategoryNameEng",
          label: this.$t("addons.name"),
          formatter: (value, key, item) =>
            `${item.productCategoryNameGeo} / ${item.productCategoryNameEng}`,
        },
        {
          key: "subCategories.length",
          label: this.$t("addons.subcatNumber"),
        },
      ];
    },
  },
  watch: {
    take() {
      this.handleGetCategories();
    },
    searchKey() {
      this.handleGetCategories();
    },
    id() {
      this.handleGetCategories();
    },
    sortOrder() {
      this.handleGetCategories();
    },
  },
  created() {
    this.handleGetCategories();
  },
  methods: {
    ...mapActions("parametersModule", [
      "getCategories",
      "getCategory",
      "deleteAnyCategory",
      "getCategory",
    ]),
    ...mapActions("categoryManagers", ["exportCategories"]),
    toast(title = "success", variant = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "FileIcon",
          variant,
        },
      });
    },
    async exportHandler() {
      try {
        const response = await this.exportCategories();
        const date = new Date().toLocaleDateString();
        FileSaver.saveAs(response.data, `Categories-${date}.xlsx`);
        this.toast("Exported successfully", "success");
      } catch (error) {
        const responsObj = await error.response.data.text();
        this.toast(JSON.parse(responsObj).error.message, "danger");
      }
    },
    async handleGetCategories() {
      try {
        await this.getCategories({
          skip: this.skip,
          take: this.take,
          sortOrder: this.sortOrder,
          searchKey: this.searchKey,
          id: this.id,
        });
        this.categoriesList = this.categories.data.productCategories;
      } catch (error) {
        console.log(error);
      }
    },
    swalConfirmation(type = "delete", item) {
      return Swal.fire({
        title: `Are you sure you want to ${type} category - ${item}?`,
        text: `You will ${type} this category!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#28a745",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, ${type}!`,
      });
    },
    async handlePopupUpdate(id) {
      try {
        await this.getCategory({ id });
        this.catForPopup = this.category;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteCategory(type, item) {
      // console.log("delete cat", item.productCategoryNameGeo)
      const result = await this.swalConfirmation("delete", `${item.productCategoryNameGeo} / ${item.productCategoryNameEng}`);
      const payload = { type, id: item.productCategoryId };
      if (result.isConfirmed) {
        try {
          await this.deleteAnyCategory(payload);
          this.toast("Category successfully deleted", "success");
          if (type === "subCategroy" || type === "genericCategory") {
            try {
              await this.getCategory({ id: this.category.productCategoryId });
              this.catForPopup = this.category;
            } catch (error) {
              console.log(error);
            }
          }
          this.handleGetCategories();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async onClickCategoryRow(e) {
      try {
        await this.getCategory({ id: e.productCategoryId });
        this.catForPopup = this.category;
        this.$bvModal.show("category-modal");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollme {
  overflow-x: auto;
}
</style>
