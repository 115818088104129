<template>
  <div :class="`d-flex align-items-center`">
    <b-form-input
      id="region-name"
      v-model="geoName"
      type="text"
      size="sm"
      placeholder="Geo name"
      class="mr-1"
    />
    <b-form-input
      id="region-name-eng"
      v-model="engName"
      type="text"
      size="sm"
      placeholder="Eng name"
      class="mr-1"
    />
    <b-form-select
      v-if="type === 'productSubCategories'"
      v-model="typeModel"
      :options="serviceTypeList"
      size="sm"
      class="mr-1"
      value="value"
      label="label"
    />
    <b-form-select
      v-if="giftModel"
      v-model="giftModel"
      :options="GiftTypeList"
      size="sm"
      class="mr-1"
      value="value"
      label="label"
    />
    <b-form-input
      v-if="type === 'productGenericCategories'"
      id="commission"
      v-model="commission"
      type="number"
      size="sm"
      placeholder="Commission Fee Percent"
      class="mr-1"
    />
    <b-button
      v-if="areActionsAllowed"
      variant="success"
      style="padding: 0.4rem"
      @click="
        updateFunction(
          geoName,
          engName,
          id,
          type,
          serviceType,
          emotyCommissionFeePercent,
          giftType
        )
      "
    >
      <feather-icon icon="SaveIcon" size="12" class="align-text-top mb-0" />
    </b-button>
  </div>
</template>

<script>
import { BFormInput, BButton, BFormSelect } from "bootstrap-vue";
import "vue-good-table/dist/vue-good-table.css";

export default {
  components: {
    BFormInput,
    BFormSelect,
    BButton,
  },
  props: {
    nameGeo: {
      type: String,
      default: "",
    },
    nameEng: {
      type: String,
      default: "",
    },
    updateFunction: {
      type: Function,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "",
    },
    serviceType: {
      type: Number,
      default: null,
    },
    emotyCommissionFeePercent: {
      type: Number,
      default: null,
    },
    giftType: {
      type: Number,
      default: null,
    },
    areActionsAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      serviceTypeList: [
        {
          value: 1,
          text: this.$i18n.t("global.experiential"),
        },
        {
          value: 2,
          text: this.$i18n.t("global.material"),
        },
        {
          value: 3,
          text: this.$i18n.t("global.digital"),
        },
        {
          value: 4,
          text: this.$i18n.t("global.card"),
        },
      ],
      GiftTypeList: [
        {
          value: 1,
          text: this.$i18n.t("global.both"),
        },
        {
          value: 2,
          text: this.$i18n.t("global.daily"),
        },
        {
          value: 3,
          text: this.$i18n.t("global.holiday"),
        },
      ],
    };
  },
  computed: {
    isSaveButtonAllowed() {
      return (
        (this.type === "productCategories" &&
          this.isMainAndSubCategoryChangesAllowed) ||
        (this.type === "productGenericCategories" &&
          this.isGenericEditAllowed) ||
        (this.type === "productSubCategories" &&
          this.isMainAndSubCategoryChangesAllowed)
      );
    },
    engName: {
      get() {
        return this.nameEng;
      },
      set(val) {
        this.$emit("update:nameEng", val);
      },
    },
    geoName: {
      get() {
        return this.nameGeo;
      },
      set(val) {
        this.$emit("update:nameGeo", val);
      },
    },
    typeModel: {
      get() {
        return this.serviceType;
      },
      set(val) {
        this.$emit("update:serviceType", val);
      },
    },
    giftModel: {
      get() {
        return this.giftType;
      },
      set(val) {
        this.$emit("update:giftType", val);
      },
    },
    commission: {
      get() {
        return this.emotyCommissionFeePercent;
      },
      set(val) {
        this.$emit("update:emotyCommissionFeePercent", val);
      },
    },
  },
};
</script>
