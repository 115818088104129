<template>
  <div>
    <b-form-group label-for="Generic Category" class="mt-1">
      <div
        class="p-1 mb-1 border rounded-sm d-flex justify-content-between align-items-center"
      >
        <b-form-input
          v-model="nameGeo"
          placeholder="Generic Cat Geo name"
          class="mr-1"
          size="sm"
        />
        <b-form-input
          v-model="nameEng"
          placeholder="Generic Cat Eng name"
          class="mr-1"
          size="sm"
        />
        <b-form-select
          v-model="giftType"
          :options="GiftTypeList"
          class="gift-type-select mr-1"
          size="sm"
        />
        <b-input-group class="percent-input">
          <b-form-input
            v-model="emotyCommissionFeePercent"
            placeholder="0"
            size="sm"
          />
        </b-input-group>
        <b-button
          variant="primary"
          size="sm"
          class="ml-1"
          @click="handleSubmit()"
        >
          <feather-icon size="16" icon="PlusIcon" />
        </b-button>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
  },
  props: {
    handleAddingGenericCategory: {
      type: Function,
      default: () => {},
    },
    subCategoryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      nameGeo: "",
      nameEng: "",
      giftType: "",
      emotyCommissionFeePercent: "",
    };
  },
  computed: {
    GiftTypeList() {
      return [
        {
          value: 1,
          text: "Both",
        },
        {
          value: 2,
          text: "Daily",
        },
        {
          value: 3,
          text: "Holiday",
        },
      ];
    },
  },
  methods: {
    handleSubmit() {
      this.handleAddingGenericCategory({
        nameGeo: this.nameGeo,
        nameEng: this.nameEng,
        giftType: this.giftType,
        emotyCommissionFeePercent: this.emotyCommissionFeePercent,
        subCategoryId: this.subCategoryId,
      });
      this.nameGeo = "";
      this.nameEng = "";
      this.giftType = "";
      this.emotyCommissionFeePercent = "";
    },
  },
};
</script>
