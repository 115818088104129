var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:"d-flex align-items-center"},[_c('b-form-input',{staticClass:"mr-1",attrs:{"id":"region-name","type":"text","size":"sm","placeholder":"Geo name"},model:{value:(_vm.geoName),callback:function ($$v) {_vm.geoName=$$v},expression:"geoName"}}),_c('b-form-input',{staticClass:"mr-1",attrs:{"id":"region-name-eng","type":"text","size":"sm","placeholder":"Eng name"},model:{value:(_vm.engName),callback:function ($$v) {_vm.engName=$$v},expression:"engName"}}),(_vm.type === 'productSubCategories')?_c('b-form-select',{staticClass:"mr-1",attrs:{"options":_vm.serviceTypeList,"size":"sm","value":"value","label":"label"},model:{value:(_vm.typeModel),callback:function ($$v) {_vm.typeModel=$$v},expression:"typeModel"}}):_vm._e(),(_vm.giftModel)?_c('b-form-select',{staticClass:"mr-1",attrs:{"options":_vm.GiftTypeList,"size":"sm","value":"value","label":"label"},model:{value:(_vm.giftModel),callback:function ($$v) {_vm.giftModel=$$v},expression:"giftModel"}}):_vm._e(),(_vm.type === 'productGenericCategories')?_c('b-form-input',{staticClass:"mr-1",attrs:{"id":"commission","type":"number","size":"sm","placeholder":"Commission Fee Percent"},model:{value:(_vm.commission),callback:function ($$v) {_vm.commission=$$v},expression:"commission"}}):_vm._e(),(_vm.areActionsAllowed)?_c('b-button',{staticStyle:{"padding":"0.4rem"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.updateFunction(
        _vm.geoName,
        _vm.engName,
        _vm.id,
        _vm.type,
        _vm.serviceType,
        _vm.emotyCommissionFeePercent,
        _vm.giftType
      )}}},[_c('feather-icon',{staticClass:"align-text-top mb-0",attrs:{"icon":"SaveIcon","size":"12"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }