<template>
  <b-modal
    id="category-modal"
    ref="category-modal"
    scrollable
    :title="$t('addons.details')"
    hide-footer
    size="lg"
    @hidden="onHidden"
  >
    <div v-if="cat">
      <label>{{ t.t("products.category") }}</label>
      <CategoriesEdit
        :id="cat.productCategoryId"
        :name-geo.sync="cat.productCategoryNameGeo"
        :name-eng.sync="cat.productCategoryNameEng"
        type="productCategories"
        :update-function="handleCategoryParametersUpdate"
        :areActionsAllowed="areActionsAllowed"
      />
      <div
        v-for="subCategory in cat.subCategories"
        :key="subCategory.productSubCategoryId"
        class="p-1 rounded border my-2"
      >
        <div class="d-flex justify-content-between">
          <div>
            <p>{{ t.t("products.sub-category") }}:</p>
            <CategoriesEdit
              :id="subCategory.productSubCategoryId"
              :name-geo.sync="subCategory.productSubCategoryNameGeo"
              :name-eng.sync="subCategory.productSubCategoryNameEng"
              :service-type.sync="subCategory.serviceType"
              type="productSubCategories"
              :update-function="handleCategoryParametersUpdate"
              :areActionsAllowed="areActionsAllowed"
            />
          </div>
          <div class="d-flex">
            <b-button
              v-if="false && areActionsAllowed"
              variant="flat-danger"
              class="btn-icon mr-1"
              @click="
                deleteCategory('subCategory', subCategory.productSubCategoryId)
              "
            >
              <feather-icon size="16" icon="TrashIcon" />
            </b-button>
          </div>
        </div>
        <label v-if="subCategory.genericCategories.length" class="my-1">
          {{ t.t("products.generic-category") }}
        </label>
        <div
          v-for="genericCategory in subCategory.genericCategories"
          :key="genericCategory.productGenericCategoryId"
          class="p-1 mb-1 border rounded-sm d-flex justify-content-between align-items-center"
        >
          <div>
            <CategoriesEdit
              :id="genericCategory.productGenericCategoryId"
              :name-geo.sync="genericCategory.productGenericCategoryNameGeo"
              :name-eng.sync="genericCategory.productGenericCategoryNameEng"
              :gift-type.sync="genericCategory.giftType"
              type="productGenericCategories"
              :emoty-commission-fee-percent.sync="
                genericCategory.emotyCommissionFeePercent
              "
              :update-function="handleCategoryParametersUpdate"
              :areActionsAllowed="areActionsAllowed"
            />
          </div>
          <div>
            <b-button
              v-if="false && areActionsAllowed"
              variant="flat-danger"
              class="btn-icon"
              @click="
                deleteCategory(
                  'genericCategory',
                  genericCategory.productGenericCategoryId
                )
              "
            >
              <feather-icon size="16" icon="TrashIcon" />
            </b-button>
          </div>
        </div>
        <!--  -->
        <AddGenericCategoryToSubCategory
          :handleAddingGenericCategory="handleAddingGenericCategory"
          :subCategoryId="subCategory.productSubCategoryId"
        />
        <!--  -->
      </div>
    </div>
  </b-modal>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { BButton } from "bootstrap-vue";
import { mapActions } from "vuex";
import isAllowed from "@core/utils/isAllowed";
import CategoriesEdit from "@/views/parameters/components/CategoriesEdit.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddGenericCategoryToSubCategory from "./components/AddGenericCategoryToSubCategory.vue";

export default {
  components: {
    CategoriesEdit,
    BButton,
    AddGenericCategoryToSubCategory,
  },
  props: {
    handlePopupUpdate: {
      type: Function,
      default: () => {},
    },
    category: {
      type: Object,
      default: () => {},
    },
    deleteCategory: {
      type: Function,
      default: () => {},
    },
    fetch: {
      type: Function,
      default: () => {},
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cat: null,
    };
  },
  computed: {
    areActionsAllowed() {
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin", "Editor"], roles);
    },
    t() {
      return this.$i18n;
    },
    serviceTypeList() {
      return {
        1: this.$i18n.t("global.experiential"),
        2: this.$i18n.t("global.material"),
        3: this.$i18n.t("global.digital"),
        4: this.$i18n.t("global.card"),
      };
    },
    giftTypeList() {
      return {
        1: this.$i18n.t("global.both"),
        2: this.$i18n.t("global.daily"),
        3: this.$i18n.t("global.holiday"),
      };
    },
  },
  watch: {
    category(newVal) {
      console.log("asd", newVal);
      this.cat = newVal;
    },
  },
  methods: {
    ...mapActions("parametersModule", [
      "getCategory",
      "updateAnyCategory",
      "addGenericCategoryToSubCategory",
    ]),
    async handleAddingGenericCategory(genericCategory) {
      const payload = {
        categoryId: this.cat.productCategoryId,
        subCategoryId: genericCategory.subCategoryId,
        data: {
          productGenericCategoryNameEng: genericCategory.nameEng,
          productGenericCategoryNameGeo: genericCategory.nameGeo,
          emotyCommissionFeePercent: genericCategory.emotyCommissionFeePercent,
          giftType: genericCategory.giftType,
        },
      };
      try {
        await this.addGenericCategoryToSubCategory(payload);
        this.handlePopupUpdate(this.cat.productCategoryId);
        this.fetch();
      } catch (error) {
        console.log(error);
      }
    },
    onHidden() {
      this.fetch();
    },
    fireToast(message = "success", type = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant: type,
        },
      });
    },
    handleCategoryParametersUpdate(
      nameGeo,
      nameEng,
      id,
      type,
      serviceType = null,
      emotyCommissionFeePercent = null,
      giftType = null
    ) {
      const object = {
        nameGeo,
        nameEng,
        id,
        type,
        serviceType,
        emotyCommissionFeePercent,
        giftType,
      };

      const payload = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(object).filter(([_, v]) => v != null)
      );

      this.updateAnyCategory(payload).then((response) => {
        if (response.data.error === null) {
          this.fireToast("Category successfully updated", "success");
          this.fetch();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
}
</style>
